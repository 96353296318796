import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import Menu from '@/views/app/reports/Menu'
import CastrationsTermalMap from '@/views/app/reports/CastrationsTermalMap'
import CastrationsPointMap from '@/views/app/reports/CastrationsPointMap'
import CastrationsClinicTime from '@/views/app/reports/CastrationsClinicTime'
import CastrationsByClinic from '@/views/app/reports/CastrationsByClinic'
import CastrationsByMonth from '@/views/app/reports/CastrationsByMonth'
import CastrationsByBreed from '@/views/app/reports/CastrationsByBreed'
import CastrationsByProtector from '@/views/app/reports/CastrationsByProtector'
import CastrationsByNGO from '@/views/app/reports/CastrationsByNGO'
import CastrationsByCancelationReason from '@/views/app/reports/CastrationsByCancelationReason'
import CastrationsByRejectionReason from '@/views/app/reports/CastrationsByRejectionReason'
import CastrationsTime from '@/views/app/reports/CastrationsTime'
import CastrationsAwaitingBatch from '@/views/app/reports/CastrationsAwaitingBatch'
import AnimalsInFair from '@/views/app/reports/AnimalsInFair'
import CommentsByClinicsInApprovedCastrations from '@/views/app/reports/CommentsByClinicsInApprovedCastrations'
import AnimalsTermalMap from '@/views/app/reports/AnimalsTermalMap'
import AnimalsPointMap from '@/views/app/reports/AnimalsPointMap'
import ClinicalCareItemClinicBalances from '@/views/app/reports/ClinicalCareItemClinicBalances'
import CastrationsByUser from '@/views/app/reports/CastrationsByUser'
import MonthlyAdoptionsByFair from '@/views/app/reports/MonthlyAdoptionsByFair'
import CastrationsByYear from '@/views/app/reports/CastrationsByYear'
import CastrationsByCras from '@/views/app/reports/CastrationsByCras'
import CastrationsByRegion from '@/views/app/reports/CastrationsByRegion'

function ReportsRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
      <Route exact path={path}>
        <Menu title="Petis - Parceiros / Relatórios" />
      </Route>
      <Route exact path={`${path}/castrations-awaiting-batch`}>
        <CastrationsAwaitingBatch title="Petis - Parceiros / Castrações aguardando lote" />
      </Route>
      <Route exact path={`${path}/castrations-termal-map`}>
        <CastrationsTermalMap title="Petis - Parceiros / Mapa térmico de castrações" />
      </Route>
      <Route exact path={`${path}/castrations-in-map`}>
        <CastrationsPointMap title="Petis - Parceiros / Castrações no mapa" />
      </Route>
      <Route exact path={`${path}/castrations-clinic-time`}>
        <CastrationsClinicTime title="Petis - Parceiros / Tempo de atendimento" />
      </Route>
      <Route exact path={`${path}/castrations-by-clinic`}>
        <CastrationsByClinic title="Petis - Parceiros / Castrações por clínica" />
      </Route>
      <Route exact path={`${path}/comments-by-clinics-in-approved-castrations`}>
        <CommentsByClinicsInApprovedCastrations title="Petis - Parceiros / Comentários das clínicas por castração aprovada" />
      </Route>
      <Route exact path={`${path}/castrations-by-month`}>
        <CastrationsByMonth title="Petis - Parceiros / Castrações por mês" />
      </Route>
      <Route exact path={`${path}/castrations-by-year`}>
        <CastrationsByYear title="Petis - Parceiros / Castrações por ano" />
      </Route>
      <Route exact path={`${path}/castrations-by-breed`}>
        <CastrationsByBreed title="Petis - Parceiros / Castrações por raça" />
      </Route>
      <Route exact path={`${path}/castrations-by-protector`}>
        <CastrationsByProtector title="Petis - Parceiros / Castrações por protetor(a)" />
      </Route>
      <Route exact path={`${path}/castrations-by-ngo`}>
        <CastrationsByNGO title="Petis - Parceiros / Castrações por ONG" />
      </Route>
      <Route exact path={`${path}/castrations-time`}>
        <CastrationsTime title="Petis - Parceiros / Tempo das castrações" />
      </Route>
      <Route exact path={`${path}/castrations-by-cancelation-reasons`}>
        <CastrationsByCancelationReason title="Petis - Parceiros / Castrações por motivo de cancelamento" />
      </Route>
      <Route exact path={`${path}/castrations-by-rejection-reasons`}>
        <CastrationsByRejectionReason title="Petis - Parceiros / Castrações por motivo de rejeição" />
      </Route>
      <Route exact path={`${path}/animals-in-fair`}>
        <AnimalsInFair title="Petis - Parceiros / Resumo das feiras de adoção" />
      </Route>
      <Route exact path={`${path}/monthly-adoptions-by-fair`}>
        <MonthlyAdoptionsByFair title="Petis - Parceiros / Adoções mensais por feira" />
      </Route>
      <Route exact path={`${path}/animals-termal-map`}>
        <AnimalsTermalMap title="Petis - Parceiros / Densidade demográfica de pets" />
      </Route>
      <Route exact path={`${path}/animals-in-map`}>
        <AnimalsPointMap title="Petis - Parceiros / Pets no mapa" />
      </Route>
      <Route exact path={`${path}/clinical-care-item-clinic-balances`}>
        <ClinicalCareItemClinicBalances title="Petis - Parceiros / Estoque de itens clínicos" />
      </Route>
      <Route exact path={`${path}/castrations-by-user`}>
        <CastrationsByUser title="Petis - Parceiros / Castrações por usuário" />
      </Route>
      <Route exact path={`${path}/castrations-by-cras`}>
        <CastrationsByCras title="Petis - Parceiros / Castrações solicitadas por usuários com CRAS" />
      </Route>
      <Route exact path={`${path}/castrations-by-region`}>
        <CastrationsByRegion title="Petis - Parceiros / Castrações solicitadas por região" />
      </Route>
    </div>
  )
}

export default ReportsRoutes

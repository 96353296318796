import {
  Card, Flexbox, Form, Styles,
} from '@/components'
import useAuth from '@/hooks/useAuth'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import session from '@/services/auth/session'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  AppContainer, Logo, Overflow, PasswordButton,
} from './styles'

const schema = yup.object().shape({
  login: yup.string().required(),
  password: yup.string().min(6).required(),
})

function Login({ title }) {
  const [loading, setLoading] = useState(false)
  const auth = useAuth()
  const [error, setError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const { success = '', login = '', from = { pathname: '/app' } } = useMemo(() => location?.state || {}, [location])

  usePageTitle({ title })

  const signIn = useCallback(async (form) => {
    try {
      setLoading(true)
      setError(null)
      const { data } = await session.hasLocalAuthentication(form?.login)
      if (data.hasLocalAuthentication) {
        await auth.signIn(form)
        history.replace(from)
      } else {
        history.push({
          pathname: '/reset-password/token',
          state: { login: form?.login },
        })
      }
    } catch (e) {
      setError(e)
      setLoading(false)
    }
  }, [auth, from, history])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <AppContainer>
      <Overflow />
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{
            password: '',
            login,
          }}
          validationSchema={schema}
          onSubmit={async (values) => { await signIn(values) }}
          validateOnMount
        >
          {
            ({ errors, touched, isValid }) => (
              <Form.ValidationForm>
                <Card.Base style={{ maxWidth: 350, minWidth: 300 }}>
                  <Card.Header>
                    <Flexbox.Columns className="align-center">
                      <Flexbox.Column className="col-auto">
                        <Logo />
                      </Flexbox.Column>
                      <Flexbox.Column>
                        Petis - Login
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </Card.Header>
                  <Card.Body>
                    {
                      success && !error ? (
                        <Styles.Toast className="toast-success">
                          {success}
                        </Styles.Toast>
                      ) : null
                    }
                    {
                      error ? (
                        <Styles.Toast className="toast-error">
                          {error?.message}
                        </Styles.Toast>
                      ) : null
                    }
                    <Form.Group className={`${touched?.login && errors?.login ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="login">E-mail ou CPF/CNPJ</Form.Label>
                      <Form.ValidationField
                        name="login"
                        autoFocus
                        id="login"
                        autoComplete="off"
                        placeholder="E-mail ou CPF/CNPJ"
                      />
                      {
                        touched?.login && errors?.login
                          ? <Form.Hint>{errors.login}</Form.Hint> : null
                      }
                    </Form.Group>
                    <Form.Group className={`${touched?.password && errors?.password ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="password">Senha</Form.Label>
                      <div style={{ position: 'relative' }}>
                        <Form.ValidationField
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          id="password"
                          placeholder="Senha super segura"
                        />
                        <PasswordButton
                          title={showPassword ? 'Ocultar senha' : 'Mostrar senha'}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i className="fas fa-eye-slash text-primary" />
                          ) : (
                            <i className="fas fa-eye text-primary" />
                          )}
                        </PasswordButton>
                      </div>
                      {
                        touched?.password && errors?.password
                          ? <Form.Hint>{errors.password}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Flexbox.Columns className="align-center">
                      <Flexbox.Column>
                        <Styles.Link className="btn-link btn-sm" to="/reset-password">
                          Esqueci a senha
                        </Styles.Link>
                      </Flexbox.Column>
                      <Flexbox.Column className="col-auto">
                        <Styles.Button type="submit" className="btn-primary" disabled={!isValid}>
                          <i className="fas fa-sign-in-alt" />
                            &nbsp;Entrar
                        </Styles.Button>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </Card.Footer>
                </Card.Base>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </AppContainer>
  )
}

export default Login

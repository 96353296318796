import {
 Flexbox, Form, LoadManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import { REGION } from '@/lib/enums'
import yup from '@/lib/yupPt'
import regions from '@/services/api/regions'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  description: yup.string(),
})

function Edit({ title }) {
  const [updating, setUpdating] = useState(false)
  usePageTitle({ title })
  const history = useHistory()
  const { id } = useParams()

  const { loading, error, data } = useAxiosRequest(regions.show, id)
  const onSubmit = useCallback(async (values) => {
    try {
      setUpdating(true)
      await regions.update(id, values)
      toast.success('Região alterada com sucesso')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setUpdating(false)
    }
  }, [history, id])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Editar região"
        subtitle="Alterar os detalhes da região"
        icon="fas fa-map"
      />
      <LoadManager loading={loading} error={error}>
        <fieldset disabled={updating}>
          <Form.Formik
            initialValues={{
              name: data?.name, type: data?.type, description: data?.description,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {
              ({
                errors, touched, isValid, setFieldValue,
              }) => (
                <Form.ValidationForm>
                  <Flexbox.Columns>
                    <Flexbox.Column className="col-8 col-sm-12">
                      <Form.Group>
                        <Form.Label htmlFor="inputName">Qual o nome da nova região? <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField
                          name="name"
                          id="inputName"
                          placeholder="Nome da região"
                        />
                        {
                          touched?.name && errors?.name
                            ? <Form.Hint className="text-error">{errors?.name}</Form.Hint> : null
                        }
                      </Form.Group>
                    </Flexbox.Column>
                    <Flexbox.Column className="col-4 col-sm-12">
                      <Form.Group>
                        <Form.Label htmlFor="inputType">Qual o tipo da região? <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField name="type" id="inputType">
                          {
                            ({ field }) => (
                              <Form.Select
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                                onChange={({ target: { value } }) => setFieldValue('type', value)}
                                id="inputType"
                              >
                                <option value={undefined}>Escolha o tipo...</option>
                                {
                                  Object.values(REGION.type.enum).map((type) => (
                                    <option
                                      key={type}
                                      value={type}
                                    >
                                      {REGION.type.t(type)}
                                    </option>
                                  ))
                                }
                              </Form.Select>
                            )
                          }
                        </Form.ValidationField>
                      </Form.Group>
                    </Flexbox.Column>
                    <Flexbox.Column className="col-12">
                      <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="inputDescription">Adicione uma descrição na região</Form.Label>

                        <Form.ValidationField
                          name="description"
                          id="inputDescription"
                        >
                          {
                            ({ field }) => (
                              <TextareaAutosize
                                name="description"
                                style={{ resize: 'none' }}
                                className="form-input"
                                rows={1}
                                maxRows={3}
                                placeholder="Descrição..."
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                              />
                            )
                          }
                        </Form.ValidationField>
                      </Form.Group>
                    </Flexbox.Column>
                  </Flexbox.Columns>
                  <div className="text-right mt-2">
                    <button type="submit" className={`btn btn-primary ${updating ? 'loading' : ''}`} disabled={!isValid}>
                      <i className="fas fa-check" />
                      &nbsp;Salvar
                    </button>
                  </div>
                </Form.ValidationForm>
              )
            }
          </Form.Formik>
        </fieldset>
      </LoadManager>
    </Styles.Container>
  )
}

export default Edit

import React from 'react'
import { PageNotFound, Container } from './styles'
import Flexbox from '../Flexbox'

function NotFound() {
  return (
    <Container className="grid-xl">
      <Flexbox.Columns>
        <Flexbox.Column className="col-12 col-md-12 my-2">
          <Flexbox.Columns className="align-center">
            <Flexbox.Column>
              <div className="h2 text-center">404 - Página não encontrada</div>
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns className="align-center">
            <Flexbox.Column className="">
              <PageNotFound />
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns className="align-center">
            <Flexbox.Column>
              <div className="text-center">
                Desculpe, não encontramos a página que você está procurando.
              </div>
            </Flexbox.Column>
          </Flexbox.Columns>
        </Flexbox.Column>
      </Flexbox.Columns>
    </Container>
  )
}

export default NotFound

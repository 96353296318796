import { primaryColor } from '@/styles/variables'
import styled from 'styled-components'

export const StarsContainer = styled.div`
  padding-bottom: 4px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 80px;
  gap: 2px;
`

export const StarIcon = styled.i`
  width: 16px;
  text-align: center;
`

export const EmptySpace = styled.span`
  width: 16px;
  display: inline-block;
`

export const BarContainer = styled.div`
  background-color: #ddd;
  width: 100%;
  height: 8px;
  position: relative;
  border-radius: 2px;
`

export const BarFill = styled.div`
  height: 8px;
  background-color: ${primaryColor};
  position: absolute;
  border-radius: 2px;
  width: ${({ barWidth }) => `${barWidth}%`};
`

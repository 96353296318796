import envs from '@/constants/envs'
import { useMap } from '@vis.gl/react-google-maps'
import React from 'react'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'

function PlaceAutocomplete() {
  const map = useMap()

  return (
    <div style={{
 display: 'block', minWidth: 300, width: '100%', zIndex: 1000,
}}
    >
      <GooglePlacesAutocomplete
        apiOptions={{ language: 'pt-BR' }}
        selectProps={{
          placeholder: 'Pesquise um local',
          loadingMessage: () => 'Buscando locais',
          noOptionsMessage: () => 'Nenhum local encontrado',
          onChange: (v) => geocodeByPlaceId(v.value.place_id)
            .then((res) => {
              if (res && res.length > 0) {
                map.fitBounds(res[0].geometry.viewport)
              }
            }),
        }}
        apiKey={envs.REACT_APP_GOOGLE_MAPS_API_KEY}

      />
    </div>
  )
}

export default PlaceAutocomplete

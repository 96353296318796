import api from './api'

export default {

  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/regions', { params })
  },

  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/regions/${id}`)
  },

  destroy(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/regions/${id}`)
  },

  update(id, body) {
    return api.put(`/partner-licensings/:partnerLicensingId/regions/${id}`, body)
  },

  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/regions', body)
  },

  updatePolygon(id, body) {
    return api.patch(`/partner-licensings/:partnerLicensingId/regions/${id}/polygon`, body)
  },
}

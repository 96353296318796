import {
  Flexbox,
  Form,
  ListManager,
  Paginator,
  QuickFilter,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import clinics from '@/services/api/clinics'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import TransactionRow from '../components/TransactionRow'

function Transactions({ title }) {
  usePageTitle({ title })

  const query = useQuery()
  const { id } = useParams()
  const history = useHistory()
  const { data, error, loading } = useAxiosRequest(clinics.transactions, id, query)

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Histórico de transações</div>
        </div>
        <div className="modal-body">
          <div className="content">
            <QuickFilter
              initialValues={{
                description_cont: '',
                createdAt_between: [null, null],
              }}
              title="Filtrar meus pets"
              renderForm={({ values, setFieldValue }) => (
                <>
                  <Flexbox.Column className="pt-2">
                    <Form.Group>
                      <Form.Label
                        className="label-sm pt-2"
                        htmlFor="description_cont"
                      >
                        Descrição
                      </Form.Label>
                      <Form.ValidationField
                        name="description_cont"
                        className="input-sm"
                        id="description_cont"
                        autoComplete="none"
                        placeholder="Descrição da transação"
                      />
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-4 pt-2">
                    <Form.Group>
                      <Form.Label
                        className="label-sm pt-2"
                        htmlFor="createdAt_between"
                      >
                        Data da transação
                      </Form.Label>
                      <Form.DatePickerField
                        placeholderText="De - Até"
                        dateFormat="dd/MM/yyyy"
                        startDate={
                          values.createdAt_between[0]
                            ? new Date(values.createdAt_between[0])
                            : null
                        }
                        endDate={
                          values.createdAt_between[1]
                            ? new Date(values.createdAt_between[1])
                            : null
                        }
                        selectsEnd
                        className="form-input input-sm"
                        selectsRange
                        isClearable
                        name="createdAt_between"
                        onChange={(v) => {
                          const [start, end] = v || []
                          if (!start && !end) setFieldValue('createdAt_between', [])

                          const formattedValues = []
                          if (start) formattedValues.push(start)
                          if (end) formattedValues.push(new Date(end.setUTCHours(23, 59, 59, 999)))

                          setFieldValue('createdAt_between', formattedValues)
                        }}
                      />
                    </Form.Group>
                  </Flexbox.Column>
                </>
              )}
            />
            <ListManager loading={loading} count={data?.count || 0} error={error}>
              <cite>Lista de todas as transações da clínica</cite>
              <div className="divider" />
              {
                data?.rows?.map((transaction) => (
                  <TransactionRow key={transaction?.id} transaction={transaction} />
                ))
              }
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </ListManager>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transactions

export const DrawingActionKind = {
  SET_OVERLAY: 'SET_OVERLAY',
  UPDATE_OVERLAYS: 'UPDATE_OVERLAYS',
  UNDO: 'UNDO',
  REDO: 'REDO',
}

export function isPolygon(
  overlay,
) {
  return (overlay).getPath !== undefined
}

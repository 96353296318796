import {
  AddressForm, Flexbox, Form, Mask,
} from '@/components'
import address from '@/lib/formatters/address'
import yup from '@/lib/yupPt'
import adoptionFairs from '@/services/api/adoptionFairs'
import gmaps from '@/services/gmaps'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  title: yup.string().trim().required(),
  description: yup.string().trim().required().min(5),
  restrict: yup.boolean(),
  beginIn: yup.date().required(),
  endIn: yup.date().required(),
  address: yup.object().shape({
    zipcode: yup.string().trim().required().length(8),
    city: yup.object().shape({
      id: yup.number().required(),
      name: yup.string(),
      uf: yup.string(),
    }),
    street: yup.string().trim().required(),
    number: yup.string().trim().required(),
    complement: yup.string().trim(),
  }),
  email: yup.string().trim().email(),
  phoneNumber: yup.string().trim(),
})
function FormFair({
  fair, onFairSave, btnClass, btnText, btnIcon,
}) {
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)

  const onSubmit = useCallback(async (body) => {
    try {
      setLoading(true)
      const formattedAddress = address(body?.address)
      const { location } = await gmaps.geolocation(formattedAddress)
      const { lat, lng } = location
      const point = { type: 'Point', coordinates: [lng, lat] }
      const { data } = fair
        ? await adoptionFairs.update(fair.id, { ...body, location: point })
        : await adoptionFairs.create({ ...body, location: point })
      onFairSave(data)
      toast.success('Feira salva com sucesso')
      setActive(false)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [fair, onFairSave])

  return (
    <>

      <button
        type="button"
        className={btnClass}
        onClick={() => setActive(true)}
      >
        <i className={btnIcon} />
        &nbsp;{btnText}
      </button>
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{
            description: fair?.description || '',
            title: fair?.title,
            restrict: fair?.restrict,
            beginIn: fair?.beginIn ? new Date(fair?.beginIn) : null,
            endIn: fair?.endIn ? new Date(fair?.endIn) : null,
            address: {
              id: fair?.address?.id,
              zipcode: fair?.address?.zipcode,
              city: {
                id: fair?.address?.city?.id,
                name: fair?.address?.city?.name,
                uf: fair?.address?.city?.uf,
              },
              street: fair?.address?.street,
              district: fair?.address?.district,
              number: fair?.address?.number,
              complement: fair?.address?.complement,
            },
            phoneNumber: fair?.phoneNumber,
            email: fair?.email,
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {
            ({
              errors, touched, isValid, setFieldValue, values,
            }) => (
              <Form.ValidationForm>
                <div className={`modal ${active ? 'active' : ''}`}>
                  <a type="button" className="modal-overlay" aria-label="Close" onClick={() => setActive(false)} />
                  <div className="modal-container">
                    <div className="modal-header">
                      <a
                        type="button"
                        className="btn btn-clear float-right"
                        onClick={() => setActive(false)}
                      />
                      <div className="modal-title h5">
                        {fair ? 'Editar feira de adoção' : 'Criar feira de adoção'}
                      </div>
                    </div>
                    <div className="modal-body" style={{ minHeight: 480 }}>
                      <div className="divider text-center" data-content="Detalhes da feira" />
                      <Flexbox.Columns>
                        <Flexbox.Column className="col-12">
                          <Form.Group className={`${touched?.title && errors?.title ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="title">Título <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="title"
                              id="title"
                              placeholder="Feira de adoção"
                            />
                            {
                              touched?.title && errors?.title
                                ? <Form.Hint className="text-error">{errors.title}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-6">
                          <Form.Group className={`${touched?.beginIn && errors?.beginIn ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="beginIn">Início <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="beginIn"
                              id="beginIn"
                            >
                              {
                                ({ field }) => (
                                  <Form.DatePickerField
                                    locale="pt-BR"
                                    showTimeSelect
                                    name="beginIn"
                                    placeholderText="Início"
                                    timeInputLabel="Hora"
                                    onChange={(value) => setFieldValue('beginIn', value)}
                                    selected={field.value}
                                    minDate={new Date()}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    className="form-input"
                                  />
                                )
                              }
                            </Form.ValidationField>
                            {
                              touched?.beginIn && errors?.beginIn
                                ? <Form.Hint className="text-error">{errors.beginIn}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-6">
                          <Form.Group className={`${touched?.endIn && errors?.endIn ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="endIn">Final <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="endIn"
                              id="endIn"
                            >
                              {
                                ({ field }) => (
                                  <Form.DatePickerField
                                    locale="pt-BR"
                                    showTimeSelect
                                    disabled={!values?.beginIn}
                                    name="endIn"
                                    placeholderText="Final"
                                    timeInputLabel="Hora"
                                    onChange={(value) => setFieldValue('endIn', value)}
                                    selected={field.value}
                                    minDate={values?.beginIn}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    className="form-input"
                                  />
                                )
                              }
                            </Form.ValidationField>
                            {
                              touched?.endIn && errors?.endIn
                                ? <Form.Hint className="text-error">{errors.endIn}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-12">
                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">Descrição <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="description"
                              id="description"
                            >
                              {
                                ({ field }) => (
                                  <textarea
                                    name="description"
                                    className="form-input"
                                    rows={5}
                                    placeholder="A feira ocorrerá da seguinte maneira..."
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                  />
                                )
                              }
                            </Form.ValidationField>
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-12">
                          <Form.Group>
                            <Form.Label htmlFor="restrict">Restrição</Form.Label>
                            <Form.Switch className="form-switch" key={values?.restrict} style={{ marginTop: -8 }}>
                              <Form.ValidationField
                                type="checkbox"
                                name="restrict"
                                id="restrict"
                                value={values?.restrict}
                                disabled={fair}
                                checked={
                                  Boolean(values?.restrict)
                                }
                              />
                              <i className="form-icon" />
                              &nbsp;Restrita
                            </Form.Switch>
                            <div style={{ marginTop: -8 }}>
                              <small className="text-gray">
                                Se ativa, significa que apenas o organizador da feira
                                pode adicionar animais à ela
                              </small>
                            </div>
                            <div style={{ marginTop: -5 }}>
                              <small className="text-error" hidden={fair}>
                                Atenção: a definição de restrição não poderá ser alterada depois
                              </small>
                            </div>
                          </Form.Group>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                      <div className="pt-2 mt-2">
                        <div className="divider text-center" data-content="Endereço" />
                        <AddressForm
                          touched={touched}
                          errors={errors}
                          value={values?.address}
                          onChange={setFieldValue}
                        />
                      </div>
                      <div className="pt-2 mt-2">
                        <div className="divider text-center" data-content="Opções de contato" />
                        <div style={{ marginTop: -8 }}>
                          <small className="text-primary text-center">
                            As opções de contato adicionadas aqui
                            serão exibidas para os usuários na página da feira.
                          </small>
                        </div>
                        <div style={{ marginTop: -8 }}>
                          <small className="text-primary text-center">
                            Elas facilitarão o contato
                            da população com o organizador da feira.
                          </small>
                        </div>
                        <Flexbox.Columns>
                          <Flexbox.Column className="col-12">
                            <Form.Group>
                              <Form.Label htmlFor="email">E-mail</Form.Label>
                              <Form.ValidationField
                                name="email"
                                id="email"
                                placeholder="emailparacontato@email.com"
                              />
                            </Form.Group>
                          </Flexbox.Column>
                          <Flexbox.Column className="col-6">
                            <Form.Group>
                              <Form.Label htmlFor="phoneNumber">Telefone</Form.Label>
                              <Form.ValidationField name="phoneNumber">
                                {
                                  ({ field }) => (
                                    <Mask.Phone
                                      id="phoneNumber"
                                      placeholder="(00) 99999-9999"
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...field}
                                      onAccept={(v) => setFieldValue('phoneNumber', v)}
                                    />
                                  )
                                }
                              </Form.ValidationField>
                            </Form.Group>
                          </Flexbox.Column>
                        </Flexbox.Columns>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" disabled={!isValid} className={`btn btn-primary ${loading ? 'loading' : ''}`}>
                        <i className="fas fa-save" />
                        &nbsp;Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </>
  )
}

FormFair.propTypes = {
  fair: PropTypes.objectOf(Object),
  onFairSave: PropTypes.func,
  btnClass: PropTypes.string,
  btnText: PropTypes.string,
  btnIcon: PropTypes.string,
}

FormFair.defaultProps = {
  fair: null,
  onFairSave: () => { },
  btnClass: 'btn btn-primary mx-2',
  btnText: 'Adicionar',
  btnIcon: 'fas fa-plus',
}

export default FormFair

import {
  Avatar,
  Card,
  Flexbox,
  ListManager,
  PageHeader,
  Paginator,
  Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import ratings from '@/services/api/ratings'
import React from 'react'
import dateMask from '@/lib/masks/date'
import { Link } from 'react-router-dom'
import Stars from './Stars'
import FilterRatings from './Filter'

function Ratings({ title }) {
  usePageTitle({ title })
  const query = useQuery()

  const { loading, data, error } = useAxiosRequest(ratings.list, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Avaliações das Clínicas"
        icon="fas fa-star"
        subtitle="Avaliações realizadas pelos usuários das clínicas parceiras"
        rightAction={() => (
          <>
            <FilterRatings clinicsList={data?.clinicsList} />
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          {Boolean(data)
            && data.rows.map((rating) => (
              <Flexbox.Columns className="mt-2" key={rating?.id}>
                <Flexbox.Column className="col-12 col-sm-12 my-2">
                  <Card.Base>
                    <Card.Header>
                      <Flexbox.Columns className="align-center">
                        <Flexbox.Column>
                          <Link
                            className="text-bold text-dark"
                            to={`/app/clinics/${rating?.license?.status}/${rating?.license?.id}`}
                          >
                            <div className="h5">{rating?.rated?.fullName}</div>
                          </Link>
                          <cite className="text-small">
                            {rating?.rated?.address?.formattedAddress}
                          </cite>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-auto">
                          <Link
                            to={`/app/castrations/status/${rating?.castration?.status}/${rating?.castration?.id}`}
                            className="btn btn-sm btn-primary"
                          >
                            <i className="fas fa-eye" />
                            &nbsp;Ver castração
                          </Link>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                      <div className="divider" />
                    </Card.Header>
                    <Card.Body>
                      <Flexbox.Columns>
                        <Flexbox.Column className="col-auto">
                          <Avatar
                            avatarSize="lg"
                            name={rating?.evaluator?.fullName}
                            photoURL={rating?.evaluator?.photoURL}
                            alt="Imagem do avaliador"
                          />
                        </Flexbox.Column>
                        <Flexbox.Column>
                          <Link
                            to={`/app/petis-users/${rating?.evaluator?.id}`}
                            className="text-dark"
                          >
                            <div className="text-bold">
                              {rating?.evaluator?.fullName}
                            </div>
                          </Link>
                          <div className="text-small d-flex align-items-center gap-2">
                            <Stars rate={rating?.rate} />
                            <span className="px-2" />
                            <div className="text-gray">
                              {dateMask(rating?.createdAt)}
                            </div>
                          </div>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </Card.Body>
                    <Card.Footer className="text-small">
                      <div>{rating?.description}</div>
                    </Card.Footer>
                  </Card.Base>
                </Flexbox.Column>
              </Flexbox.Columns>
            ))}
          {Boolean(data) && (
            <div className="float-right my-2">
              <Paginator paginatedList={data} />
            </div>
          )}
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default Ratings

/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useEffect, useReducer, useRef } from 'react'
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'

import reducer, {
  useDrawingManagerEvents,
  useOverlaySnapshots,
} from './UndoRedo'

import { DrawingActionKind } from './types'

export const UndoRedoControl = ({ drawingManager, onChangePolygon, polygon }) => {
  const map = useMap()
  const maps = useMapsLibrary('maps')

  const [state, dispatch] = useReducer(reducer, {
    past: [],
    now: [],
    future: [],
  })
  const overlaysShouldUpdateRef = useRef(false)

  useEffect(() => {
    onChangePolygon(state.now?.[0]?.snapshot)
  }, [state.now, onChangePolygon])

  useEffect(() => {
    if (maps) {
      if (polygon) {
        const paths = polygon.coordinates[0].map(([lng, lat]) => ({ lat, lng }))
        const overlay = new maps.Polygon({
          editable: false,
          draggable: false,
          fillColor: '#e49042',
          fillOpacity: 0.5,
          strokeColor: '#e49042',
          map,
          paths,
        })
        dispatch({ type: DrawingActionKind.SET_OVERLAY, payload: { overlay } })
        dispatch({ type: DrawingActionKind.UPDATE_OVERLAYS })
      }
    }
  }, [map, maps, polygon])

  useDrawingManagerEvents(drawingManager, overlaysShouldUpdateRef, dispatch)
  useOverlaySnapshots(map, state, overlaysShouldUpdateRef)

  return (
    <div>
      <button
        className="c-hand"
        type="button"
        onClick={() => dispatch({ type: DrawingActionKind.UNDO })}
        disabled={!state.past.length}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
        >
          <path d="M280-200v-80h284q63 0 109.5-40T720-420q0-60-46.5-100T564-560H312l104 104-56 56-200-200 200-200 56 56-104 104h252q97 0 166.5 63T800-420q0 94-69.5 157T564-200H280Z" />
        </svg>
      </button>
      <button
        className="c-hand"
        type="button"
        onClick={() => dispatch({ type: DrawingActionKind.REDO })}
        disabled={!state.future.length}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
        >
          <path d="M396-200q-97 0-166.5-63T160-420q0-94 69.5-157T396-640h252L544-744l56-56 200 200-200 200-56-56 104-104H396q-63 0-109.5 40T240-420q0 60 46.5 100T396-280h284v80H396Z" />
        </svg>
      </button>
    </div>
  )
}

/* eslint-disable react/no-array-index-key */
import { Flexbox } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import {
  BarContainer,
  BarFill,
  EmptySpace,
  StarIcon,
  StarsContainer,
} from './styles'

function Bars({ stars, count, maxCount }) {
  const barWidth = (count / maxCount) * 100

  return (
    <Flexbox.Columns className="align-center" style={{ gap: '8px' }}>
      <StarsContainer>
        {Array(5)
          .fill(null)
          .map((_, index) => (index >= 5 - stars ? (
              <StarIcon key={index} className="fas fa-star text-primary" />
            ) : (
              <EmptySpace key={index} />
            )))}
      </StarsContainer>
      <Flexbox.Column style={{ flexGrow: 8, maxWidth: '300px' }}>
        <BarContainer>
          <BarFill barWidth={barWidth} />
        </BarContainer>
      </Flexbox.Column>
      <Flexbox.Column className="text-gray text-small text-left">
        {count}
      </Flexbox.Column>
    </Flexbox.Columns>
  )
}
Bars.propTypes = {
  stars: PropTypes.number.isRequired,
  count: PropTypes.string.isRequired,
  maxCount: PropTypes.number.isRequired,
}

export default Bars

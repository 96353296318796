import Login from '@/views/auth/Login'
import React, { useReducer } from 'react'
import ResetPassword from '@/views/auth/ResetPassword'
import ResetPasswordToken from '@/views/auth/ResetPassword/Token'
import Home from '@/views/Home'
import SelectLicensing from '@/views/SelectLicensing'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import licensingContext from '@/contexts/licensingContext'
import licensingReducer, { INITIAL_STATE } from '@/reducers/licensing'
import PageNotFound from '@/views/PageNotFound'
import App from './App'
import AuthContainer from './components/AuthContainer'
import GuestRoute from './components/GuestRoute'
import LicensingContainer from './components/LicensingContainer'
import PrivateRoute from './components/PrivateRoute'
import ProvideAuth from './components/ProvideAuth'

export default function Routes() {
  const [state, dispatch] = useReducer(licensingReducer, INITIAL_STATE)

  return (
    <licensingContext.Provider value={{ state, dispatch }}>
      <ProvideAuth>
        <AuthContainer>
          <>
            <Router>
              <Switch>
                <GuestRoute exact path="/">
                  <Home />
                </GuestRoute>
                <GuestRoute exact path="/login">
                  <Login title="Petis - Parceiros / Login" />
                </GuestRoute>
                <GuestRoute exact path="/reset-password">
                  <ResetPassword title="Petis - Parceiros / Recuperar senha" />
                </GuestRoute>
                <GuestRoute exact path="/reset-password/token">
                  <ResetPasswordToken title="Petis - Parceiros / Nova senha" />
                </GuestRoute>
                <PrivateRoute exact path="/select-licensing">
                  <SelectLicensing title="Petis - Parceiros / Licença" />
                </PrivateRoute>
                <PrivateRoute path="/app">
                  <LicensingContainer>
                    <App />
                  </LicensingContainer>
                </PrivateRoute>
                <Route component={PageNotFound} />
              </Switch>
            </Router>
          </>
        </AuthContainer>
      </ProvideAuth>
    </licensingContext.Provider>
  )
}

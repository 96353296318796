export default function geometryPolygonToGeoJSON(snapshot) {
  if (!snapshot) return null
  const coordinatesMap = snapshot.path.map((snap) => [
    snap.lng(),
    snap.lat(),
  ])

  const firstLat = coordinatesMap[0][0]
  const firstLng = coordinatesMap[0][1]

  const lastPosition = coordinatesMap.length - 1
  const lastLat = coordinatesMap[lastPosition][0]
  const lastLng = coordinatesMap[lastPosition][1]

  if (firstLat !== lastLat || firstLng !== lastLng) {
    coordinatesMap.push([firstLat, firstLng])
  }

  return { type: 'polygon', coordinates: [coordinatesMap] }
}

import {
  Flexbox,
  ListManager,
  PageHeader,
  Paginator,
  Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import reports from '@/services/api/reports'
import React from 'react'
import FilterCastrations from '../components/FilterCastrations'
import CardItem from './CardItem'

function CastrationsByYear({ title }) {
  usePageTitle({ title })

  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(
    reports.castrationsByYear,
    query,
  )

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações por ano"
        subtitle="Quantidade de castrações por ano"
        icon="fas fa-hourglass-half"
        rightAction={() => <FilterCastrations />}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        {Boolean(data) && (
          <>
            <Flexbox.Columns>
              <Flexbox.Column className="col-6 col-sm-12 my-2">
                <CardItem
                  item={data?.totalStatus}
                  statusList={data?.statusList}
                />
              </Flexbox.Column>
              {data?.rows?.map((item) => (
                <Flexbox.Column key={item} className="col-6 col-sm-12 my-2">
                  <CardItem item={item} statusList={data?.statusList} />
                </Flexbox.Column>
              ))}
            </Flexbox.Columns>
            {Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )}
          </>
        )}
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsByYear

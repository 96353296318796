import castrationContext from '@/contexts/castrationContext'
import castrationReducer, { INITIAL_STATE } from '@/reducers/castration'
import Castrations from '@/views/app/Castrations'
import Show from '@/views/app/Castrations/Show'
import Approve from '@/views/app/Castrations/Show/Approve'
import Correction from '@/views/app/Castrations/Show/Correction'
import EventDetails from '@/views/app/Castrations/Show/EventDetails'
import Reject from '@/views/app/Castrations/Show/Reject'
import Cancel from '@/views/app/Castrations/Show/Cancel'
import Revision from '@/views/app/Castrations/Show/Revision'
import Status from '@/views/app/Castrations/Status'
import React, { useReducer } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import ApproveWithClinic from '@/views/app/Castrations/Show/ApproveWithClinic'
import ChangeClinic from '@/views/app/Castrations/Show/ChangeClinic'
import Confirm from '@/views/app/Castrations/Show/Confirm'
import Reopen from '@/views/app/Castrations/Show/Reopen'

function CastrationsRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(castrationReducer, INITIAL_STATE)

  return (
    <div>
      <Route exact path={path}>
        <Castrations title="Petis - Parceiros / Castrações" />
      </Route>
      <Route exact path={`${path}/status/:status`}>
        <Status title="Petis - Parceiros / Castrações" />
      </Route>
      <castrationContext.Provider value={{ state, dispatch }}>
        <Route path={`${path}/status/:status/:id`}>
          <Show title="Petis - Parceiros / Castração" />
        </Route>
        <Route path={`${path}/status/:status/:id/approve`}>
          <Approve title="Petis - Parceiros / Aprovar castração" />
        </Route>
        <Route path={`${path}/status/:status/:id/change-clinic`}>
          <ChangeClinic title="Petis - Parceiros / Trocar clínica" />
        </Route>
        <Route path={`${path}/status/:status/:id/approve-with-clinic`}>
          <ApproveWithClinic title="Petis - Parceiros / Aprovar castração" />
        </Route>
        <Route path={`${path}/status/:status/:id/reopen`}>
          <Reopen title="Petis - Parceiros / Reabrir castração" />
        </Route>
        <Route path={`${path}/status/:status/:id/reject`}>
          <Reject title="Petis - Parceiros / Rejeitar castração" />
        </Route>
        <Route path={`${path}/status/:status/:id/cancel`}>
          <Cancel title="Petis - Parceiros / Cancelar castração" />
        </Route>
        <Route path={`${path}/status/:status/:id/revision`}>
          <Revision title="Petis - Parceiros / Enviar castração para revisão" />
        </Route>
        <Route path={`${path}/status/:status/:id/correction`}>
          <Correction title="Petis - Parceiros / Enviar castração para correção" />
        </Route>
        <Route path={`${path}/status/:status/:id/events/:eventIndex`}>
          <EventDetails title="Petis - Parceiros / Detalhes do evento" />
        </Route>
        <Route path={`${path}/status/:status/:id/confirm`}>
          <Confirm title="Petis - Parceiros / Confirmar castração" />
        </Route>
      </castrationContext.Provider>
    </div>
  )
}

export default CastrationsRoutes

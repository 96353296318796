/* eslint-disable react/jsx-props-no-spreading */
import { Filter, Form } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'

function FilterRatings({ clinicsList }) {
  return (
    <Filter
      initialValues={{
        'rated.id_eq': '',
        rate_eq: '',
        orderBy: '',
      }}
      title="Filtrar avaliações"
      renderForm={() => (
        <>
          <Form.Group>
            <Form.Label htmlFor="rated.id_eq">Clínica</Form.Label>
            <Form.ValidationField name="['rated.id_eq']">
              {({ field }) => (
                <Form.Select
                  {...field}
                  id="rated.id_eq"
                  className="select-sm"
                  placeholder="Nome da clínica"
                >
                  <option value="">Todas</option>
                  {clinicsList?.map((item) => (
                    <option value={item.licensedId} key={item.licensedId}>
                      {item?.fullName}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Form.ValidationField>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="rate_eq">Avaliação</Form.Label>
            <Form.ValidationField name="rate_eq">
              {({ field }) => (
                <Form.Select
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id="rate_eq"
                  className="select-sm"
                  placeholder="Nota da clínica"
                >
                  <option value="">Todas</option>
                  <option value="5">★★★★★</option>
                  <option value="4">★★★★</option>
                  <option value="3">★★★</option>
                  <option value="2">★★</option>
                  <option value="1">★</option>
                </Form.Select>
              )}
            </Form.ValidationField>
          </Form.Group>
          <div
            className="divider text-center"
            data-content="Ordenar avaliações"
          />
          <Form.Group>
            <Form.Label htmlFor="orderBy">Ordenar por</Form.Label>
            <Form.ValidationField name="orderBy">
              {({ field }) => (
                <Form.Select
                  {...field}
                  id="orderBy"
                  className="select-sm"
                  placeholder="Ordenar por"
                >
                  <option value="createdAtDesc">Mais recentes primeiro</option>
                  <option value="createdAtAsc">Mais antigas primeiro</option>
                  <option value="rateDesc">Maiores avaliações primeiro</option>
                  <option value="rateAsc">Menores avaliações primeiro</option>
                </Form.Select>
              )}
            </Form.ValidationField>
          </Form.Group>
        </>
      )}
    />
  )
}

FilterRatings.propTypes = {
  clinicsList: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      licensedId: PropTypes.number,
    }),
  ),
}

FilterRatings.defaultProps = {
  clinicsList: [],
}

export default FilterRatings

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'

/**
 *
 * @param {import('react-datepicker').ReactDatePickerProps} props
 * @returns
 */
export default function DatePickerField({ onChange, ...props }) {
  const { setFieldValue } = useFormikContext() || {}
  const [field] = useField(props)

  return (
    <DatePicker
      {...props}
      {...field}
      locale="ptBR"
      autoComplete="off"
      onChange={(val) => {
        setFieldValue(field.name, val)
        if (onChange) onChange(val)
      }}
    />
  )
}

DatePickerField.propTypes = {
  onChange: PropTypes.func,
}

DatePickerField.defaultProps = {
  onChange: null,
}

import Flexbox from '@/components/Flexbox'
import Form from '@/components/Form'
import { ANIMAL } from '@/lib/enums'
import yup from '@/lib/yupPt'
import animals from '@/services/api/animals'
import categoriesService from '@/services/api/categories'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  name: yup.string().trim().required(),
  photoURL: yup.string().url(),
  color: yup.string().trim().required(),
  description: yup.string().trim(),
  ofStreet: yup.bool().required(),
  isCastrated: yup.bool().required(),
  bornedAt: yup.date().required(),
  diedAt: yup.date(),
  size: yup.string().required(),
  gender: yup.string().required(),
  breed: yup.object().shape({ id: yup.mixed().required() }),
  weight: yup.number(),
  category: yup.object().shape({ id: yup.mixed().required() }),
})
export default function NewAnimal({ onChange }) {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [breeds, setBreeds] = useState([])

  async function fetchCategories() {
    const { data } = await categoriesService.list()
    setCategories(data)
  }

  const fetchBreeds = useCallback(async (categoryId) => {
    const { data } = await categoriesService.breedsByCategoryId(categoryId)
    setBreeds(data)
  }, [])

  useEffect(() => {
    fetchCategories()
  }, [])

  async function onSubmit(values) {
    try {
      setLoading(true)
      const { data } = await animals.create(values)
      onChange(data)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{
          name: '',
          photoURL: '',
          color: '',
          ofStreet: false,
          isCastrated: false,
          bornedAt: null,
          weight: '0',
          description: '',
          size: '',
          gender: '',
          breed: { id: undefined },
          category: { id: undefined },
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {
          ({
            touched, errors, isValid, setFieldValue, values,
          }) => (
            <Form.ValidationForm>
              <Flexbox.Columns className="p-2 align-center">
                <Flexbox.Column className="col-12">
                  <Form.Group className={`${touched?.name && errors?.name ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="name">Nome do pet <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField
                      name="name"
                      id="name"
                      placeholder="Pet da Silva"
                    />
                    {
                      touched?.name && errors?.name
                        ? <Form.Hint className="text-error">{errors.name}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12">
                  <Form.Group className={`${touched?.gender && errors?.gender ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="gender">Sexo <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField name="gender">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onChange={({ target: { value } }) => setFieldValue('gender', value)}
                            id="gender"
                          >
                            <option value={undefined}>Escolha o sexo</option>
                            {
                              Object.values(ANIMAL.gender.enum).map((gender) => (
                                <option
                                  key={gender}
                                  value={gender}
                                >
                                  {ANIMAL.gender.t(gender)}
                                </option>
                              ))
                            }
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                    {
                      touched?.gender && errors?.gender
                        ? <Form.Hint className="text-error">{errors?.gender}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12">
                  <Form.Group className={`${touched?.size && errors?.size ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="size">Porte <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField name="size">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onChange={({ target: { value } }) => setFieldValue('size', value)}
                            id="size"
                          >
                            <option value={undefined}>Escolha o porte</option>
                            {
                              Object.values(ANIMAL.size.enum).map((size) => (
                                <option
                                  key={size}
                                  value={size}
                                >
                                  {ANIMAL.size.t(size)}
                                </option>
                              ))
                            }
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                    {
                      touched?.size && errors?.size
                        ? <Form.Hint className="text-error">{errors?.size}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12">
                  <Form.Group className={`${touched?.category?.id && errors?.category?.id ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="category.id">Espécie do Pet <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField name="category.id">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onChange={({ target: { value } }) => {
                              setFieldValue('category', categories.find(({ id }) => Number(id) === Number(value)))
                              if (value) {
                                fetchBreeds(value)
                              } else {
                                setBreeds([])
                              }
                              setFieldValue('breed', { id: null })
                            }}
                            id="category.id"
                          >
                            <option value={0}>Escolha a espécie</option>
                            {
                              categories.map((category) => (
                                <option
                                  key={category.id}
                                  value={category.id}
                                >
                                  {category.description}
                                </option>
                              ))
                            }
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                    {
                      touched?.category?.id && errors?.category?.id
                        ? <Form.Hint className="text-error">{errors?.category?.id}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12">
                  <Form.Group className={`${touched?.breed?.id && errors?.breed?.id ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="breed.id">Raça do Pet <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField name="breed.id">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onChange={({ target: { value } }) => setFieldValue('breed', breeds.find(({ id }) => Number(id) === Number(value)))}
                            disabled={!breeds.length}
                            id="breed.id"
                          >
                            <option value={undefined}>Escolha a raça</option>
                            {
                              breeds.map((breed) => (
                                <option
                                  key={breed.id}
                                  value={breed.id}
                                >
                                  {breed.description}
                                </option>
                              ))
                            }
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                    {
                      touched?.breed?.id && errors?.breed?.id
                        ? <Form.Hint className="text-error">{errors?.breed?.id}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-4 col-sm-12">
                  <Form.Group className={`${touched?.color && errors?.color ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="color">Cor e sinais do pet <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField
                      name="color"
                      id="color"
                      placeholder="Amarelo com pintinhas azuis"
                    />
                    {
                      touched?.color && errors?.color
                        ? <Form.Hint className="text-error">{errors.color}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-4 col-sm-12">
                  <Form.Group>
                    <Form.Label htmlFor="bornedAt">Nascido em <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField
                      name="bornedAt"
                      id="bornedAt"
                    >
                      {
                        ({ field }) => (
                          <Form.DatePickerField
                            name="bornedAt"
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            className="form-input"
                            id="bornedAt"
                            placeholderText="Nascido em"
                            selected={field.value || null}
                          />
                        )
                      }

                    </Form.ValidationField>
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-4 col-sm-12">
                  <Form.Group>
                    <Form.Label htmlFor="weight">Peso</Form.Label>
                    <Form.ValidationField
                      name="weight"
                      id="weight"
                    >
                      {
                        ({ field }) => (
                          <CurrencyFormat
                            className="form-input"
                            thousandSeparator="."
                            decimalSeparator=","
                            suffix=" Kg"
                            placeholder="Peso em Kg"
                            value={field.value}
                            fixedDecimalScale
                            decimalScale={2}
                            allowNegative={false}
                            onValueChange={
                              ({ value }) => setFieldValue(field.name, Number(value))
                            }
                          />
                        )
                      }

                    </Form.ValidationField>
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12 mt-2 pt-2">
                  <Form.Group>
                    <Form.Switch className="form-switch" key={values?.ofStreet} style={{ marginTop: -8 }}>
                      <Form.ValidationField
                        type="checkbox"
                        name="ofStreet"
                        id="ofStreet"
                        value={values?.ofStreet}
                        checked={
                          Boolean(values?.ofStreet)
                        }
                      />
                      <i className="form-icon" />
                      &nbsp;Este é um pet de rua
                    </Form.Switch>

                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12 pt-2 mt-2">
                  <Form.Group>
                    <Form.Switch className="form-switch" key={values?.isCastrated} style={{ marginTop: -8 }}>
                      <Form.ValidationField
                        type="checkbox"
                        name="isCastrated"
                        id="isCastrated"
                        value={values?.isCastrated}
                        checked={
                          Boolean(values?.isCastrated)
                        }
                      />
                      <i className="form-icon" />
                      &nbsp;O pet é castrado
                    </Form.Switch>

                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-12">
                  <Form.Group className={`${touched?.photoURL && errors?.photoURL ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="photoURL">Você pode adicionar um avatar para o pet</Form.Label>
                    <Form.ValidationField name="photoURL">
                      {
                        ({ field }) => (
                          <Form.FileUploadField
                            name="photoURL"
                            accept="image/*"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                          />
                        )
                      }
                    </Form.ValidationField>
                    {
                      touched?.photoURL && errors?.photoURL
                        ? <Form.Hint className="text-error">{errors.photoURL}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-12">
                  <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="description">Observações</Form.Label>
                    <Form.ValidationField
                      name="description"
                      id="description"
                    >
                      {
                        ({ field }) => (
                          <ReactTextareaAutosize
                            name="description"
                            style={{ resize: 'none' }}
                            className="form-input"
                            rows={1}
                            maxRows={3}
                            placeholder="Observações..."
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                          />
                        )
                      }
                    </Form.ValidationField>
                    {
                      touched?.description && errors?.description
                        ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="mt-2 text-right">
                <button disabled={!isValid} type="submit" className="btn btn-primary">
                  <i className="fas fa-check" />
                  &nbsp;Criar animal
                </button>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}
NewAnimal.propTypes = {
  onChange: PropTypes.func.isRequired,
}

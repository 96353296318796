import {
  Flexbox, Form, PageHeader, Styles,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import { REGION } from '@/lib/enums'
import yup from '@/lib/yupPt'
import regions from '@/services/api/regions'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'

// import { Container } from './styles';

const schema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  description: yup.string(),
})

function Create({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const onSubmit = useCallback(async (values) => {
    try {
      setLoading(true)
      const { data } = await regions.create(values)
      history.replace(`/app/regions/${data.id}/polygon`)
      toast.success('Região criada com sucesso!')
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }, [history])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title="Adicionar região" subtitle="Adicione uma nova região" icon="fas fa-map" />
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{
            name: '', type: '', description: '',
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {
            ({
              errors, touched, isValid, setFieldValue,
            }) => (
              <Form.ValidationForm>
                <Flexbox.Columns>
                  <Flexbox.Column className="col-8 col-sm-12">
                    <Form.Group>
                      <Form.Label htmlFor="inputName">Qual o nome da nova região? <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="name"
                        id="inputName"
                        placeholder="Nome da região"
                      />
                      {
                        touched?.name && errors?.name
                          ? <Form.Hint className="text-error">{errors?.name}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-4 col-sm-12">
                    <Form.Group>
                      <Form.Label htmlFor="inputType">Qual o tipo da região? <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField name="type" id="inputType">
                        {
                          ({ field }) => (
                            <Form.Select
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              onChange={({ target: { value } }) => setFieldValue('type', value)}
                              id="inputType"
                            >
                              <option value={undefined}>Escolha o tipo...</option>
                              {
                                Object.values(REGION.type.enum).map((type) => (
                                  <option
                                    key={type}
                                    value={type}
                                  >
                                    {REGION.type.t(type)}
                                  </option>
                                ))
                              }
                            </Form.Select>
                          )
                        }
                      </Form.ValidationField>
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-12">
                    <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="inputDescription">Adicione uma descrição na região</Form.Label>

                      <Form.ValidationField
                        name="description"
                        id="inputDescription"
                      >
                        {
                          ({ field }) => (
                            <TextareaAutosize
                              name="description"
                              style={{ resize: 'none' }}
                              className="form-input"
                              rows={1}
                              maxRows={3}
                              placeholder="Descrição..."
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                            />
                          )
                        }
                      </Form.ValidationField>
                    </Form.Group>
                  </Flexbox.Column>
                </Flexbox.Columns>
                <div className="text-right mt-2">
                  <button type="submit" className={`btn btn-primary ${loading ? 'loading' : ''}`} disabled={!isValid}>
                    <i className="fas fa-check" />
                    &nbsp;Salvar
                  </button>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </Styles.Container>
  )
}

export default Create

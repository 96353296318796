/* eslint-disable no-fallthrough */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import { useEffect } from 'react'

import {
  isPolygon,
  DrawingActionKind,
} from './types'

export default function reducer(state, action) {
  switch (action.type) {
    case DrawingActionKind.UPDATE_OVERLAYS: {
      const overlays = state.now.map((overlay) => {
        const snapshot = {}
        const { geometry } = overlay

        if (isPolygon(geometry)) {
          snapshot.path = geometry.getPath()?.getArray()
        }

        return {
          ...overlay,
          snapshot,
        }
      })

      return {
        now: [...overlays],
        past: [...state.past, state.now],
        future: [],
      }
    }
    case DrawingActionKind.SET_OVERLAY: {
      const { overlay } = action.payload

      const snapshot = {}
      if (isPolygon(overlay)) {
        snapshot.path = overlay.getPath()?.getArray()
      }

      return {
        past: [...state.past, state.now],
        now: [
          {
            type: action.payload.type,
            geometry: action.payload.overlay,
            snapshot,
          },
        ],
        future: [],
      }
    }

    case DrawingActionKind.UNDO: {
      const last = state.past.slice(-1)[0]

      if (!last) return state

      return {
        past: [...state.past].slice(0, -1),
        now: last,
        future: state.now ? [...state.future, state.now] : state.future,
      }
    }

    case DrawingActionKind.REDO: {
      const next = state.future.slice(-1)[0]

      if (!next) return state

      return {
        past: state.now ? [...state.past, state.now] : state.past,
        now: next,
        future: [...state.future].slice(0, -1),
      }
    }
  }
}

export function useDrawingManagerEvents(
  drawingManager,
  overlaysShouldUpdateRef,
  dispatch,
) {
  useEffect(() => {
    if (!drawingManager) return

    const eventListeners = []

    const addUpdateListener = (eventName, drawResult) => {
      const updateListener = window.google.maps.event.addListener(
        drawResult.overlay,
        eventName,
        () => {
          if (eventName === 'dragstart') {
            overlaysShouldUpdateRef.current = false
          }

          if (eventName === 'dragend') {
            overlaysShouldUpdateRef.current = true
          }

          if (overlaysShouldUpdateRef.current) {
            dispatch({ type: DrawingActionKind.UPDATE_OVERLAYS })
          }
        },
      )

      eventListeners.push(updateListener)
    }

    const overlayCompleteListener = window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (drawResult) => {
        switch (drawResult.type) {
          case window.google.maps.drawing.OverlayType.POLYGON:
            ['mouseup'].forEach((eventName) =>
              addUpdateListener(eventName, drawResult))
        }

        dispatch({ type: DrawingActionKind.SET_OVERLAY, payload: drawResult })
      },
    )

    eventListeners.push(overlayCompleteListener)

    return () => {
      eventListeners.forEach((listener) =>
        window.google.maps.event.removeListener(listener))
    }
  }, [dispatch, drawingManager, overlaysShouldUpdateRef])
}

export function useOverlaySnapshots(
  map,
  state,
  overlaysShouldUpdateRef,
) {
  useEffect(() => {
    if (!map || !state.now) return

    const overlay = state.now[state.now.length - 1]

    if (overlay) {
      overlaysShouldUpdateRef.current = false

      overlay.geometry.setMap(map)

      const { path } = overlay.snapshot

      if (isPolygon(overlay.geometry)) {
        overlay.geometry.setPath(path ?? [])
      }

      overlaysShouldUpdateRef.current = true
    }

    return () => {
      if (overlay) overlay.geometry.setMap(null)
    }
  }, [map, overlaysShouldUpdateRef, state.now])

  return state
}

import { PageHeader, Styles } from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Menu({ title }) {
  usePageTitle({ title })

  const { pathname } = useLocation()

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Relatórios disponíveis"
        subtitle="Lista com os relatórios disponíveis"
        icon="fas fa-chart-area"
      />
      <table className="table">
        <thead>
          <tr>
            <th>Relatório</th>
            <th width="1" className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map" />
              Mapa térmico de castrações
              <small> (Mostra as áreas com maior frequência de solicitações)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-termal-map`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map-marker-alt" />
              Castrações no mapa
              <small> (Mostra as castrações no lugar onde foram solicitadas)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-in-map`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map" />
              Castrações por região
              <small> (Mostra as castrações pelas regiões)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-region`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map" />
              Densidade demográfica de pets
              <small> (Mostra as áreas com a maior concentração de pets)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/animals-termal-map`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map-marker-alt" />
              Pets no mapa
              <small> (Mostra os pets no lugar onde os tutores moram)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/animals-in-map`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-stopwatch" />
              Tempo das clínicas
              <small> (Média do tempo de atendimento das clínicas)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-clinic-time`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-stopwatch" />
              Tempo das castrações
              <small> (Média do tempo das castrações para tutores, {`${"ONG's"}`} e protetores)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-time`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-clinic-medical" />
              Castrações por clínica
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-clinic`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-comment" />
              Comentários das clínicas por castração aprovada
            </td>
            <td className="text-right">
              <Link to={`${pathname}/comments-by-clinics-in-approved-castrations`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-clinic-medical" />
              Castrações aguardando lote
              <small>
                &nbsp;
                (Castrações concluídas que ainda não foram adicionadas em lotes pelas clínicas)
              </small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-awaiting-batch`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-calendar" />
              Castrações por mês
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-month`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-hourglass-half" />
              Castrações por ano
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-year`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-paw" />
              Castrações por raça e espécie
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-breed`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-hand-holding-heart" />
              Castrações por protetor(a) independente
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-protector`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-building" />
              Castrações por ONG
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-ngo`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-times" />
              Castrações por motivo de cancelamento
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-cancelation-reasons`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-ban" />
              Castrações por motivo de rejeição
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-rejection-reasons`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-heart" />
              Resumo das feiras de adoção
              <small>
                &nbsp;
                (Consulta de animais e adoções nas feiras do município e online)
              </small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/animals-in-fair`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fab fa-fw fa-gratipay" />
              Adoções mensais por feira
            </td>
            <td className="text-right">
              <Link to={`${pathname}/monthly-adoptions-by-fair`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-star-of-life" />
              Estoque de itens clínicos
              <small>
                &nbsp;
                (Consulta do estoque de itens clínicos das clínicas)
              </small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/clinical-care-item-clinic-balances`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-cut" />
              Relatório de castrações por usuário
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-user`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-user-circle" />
              Relatório de usuários que estão no CRAS
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-by-cras`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </Styles.Container>
  )
}

export default Menu

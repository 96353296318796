import {
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { REGION } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import regions from '@/services/api/regions'
import React, { useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

function Regions({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const [removingId, setRemovingId] = useState(null)

  const {
    loading, data, error, setData,
  } = useAxiosRequest(regions.list, query)

  const onConfirmRemove = useCallback(async (region) => {
    try {
      setRemovingId(region.id)
      await regions.destroy(region.id)
      setData({
        ...data,
        rows: data.rows.filter((r) => r.id !== region.id),
      })
      toast.success('Região removida com sucesso')
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setRemovingId(null)
    }
  }, [data, setData])

  const onClickRemove = useCallback((region) => {
    confirmAlert({
      title: 'Excluir região',
      message: `Você está certo que deseja excluir a região "${region.name}"?`,
      buttons: [
        { label: 'Cancelar' },
        {
          label: 'Excluir',
          className: 'bg-error',
          onClick: () => onConfirmRemove(region),
        },
      ],
    })
  }, [onConfirmRemove])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Regiões"
        subtitle="Lista de regiões cadastradas"
        icon="fas fa-map"
        rightAction={() => (

          <Link to="/app/regions/form/add" className="btn btn-primary">
            <i className="fas fa-plus" />
            &nbsp;Adicionar
          </Link>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        {
          Boolean(data) && (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Região</th>
                    <th>Tipo</th>
                    <th className="text-right" width="200px">Adicionado em</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((region) => (
                      <tr key={region.id}>
                        <td>
                          {region.name}
                          <div>
                            <small>{region.description}</small>
                          </div>
                        </td>
                        <td>{REGION.type.t(region.type)}</td>
                        <td className="text-right">{dateMask(region.createdAt)}</td>
                        <td className="text-right">
                          <Link to={`/app/regions/${region.id}/polygon`} data-tooltip="Mapa" className="btn btn-sm btn-action mr-1 tooltip tooltip-left">
                            <i className="fas fa-map" />
                          </Link>
                          <Link to={`/app/regions/${region.id}/edit`} data-tooltip="Editar detalhes" className="btn btn-sm btn-primary btn-action mr-1 tooltip tooltip-left">
                            <i className="fas fa-cog" />
                          </Link>
                          <a
                            disabled={removingId === region.id}
                            onClick={() => onClickRemove(region)}
                            data-tooltip="Excluir"
                            className={`btn btn-action btn-sm btn-error tooltip tooltip-left ${removingId === region.id ? 'loading' : ''}`}
                          >
                            <i className="fas fa-trash" />
                          </a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
                <tbody />
              </table>
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </div>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default Regions

import usePageTitle from '@/hooks/usePageTitle'
import React from 'react'
import {
  Card, Flexbox, Styles, NotFound,
} from '@/components'
import { Link } from 'react-router-dom'
import { AppContainer, Logo, Overflow } from './styles'

function PageNotFound({ title }) {
  usePageTitle({ title })

  return (
    <AppContainer>
      <Overflow />
      <Styles.Container className="grid-xl">
        <Flexbox.Columns>
          <Flexbox.Column className="col-12 col-md-12 my-2">
            <Card.Base>
              <Card.Header>
                <Flexbox.Columns className="align-center">
                  <Flexbox.Column className="col-auto">
                    <Logo />
                  </Flexbox.Column>
                  <Flexbox.Column>
                    <div className="h4">Petis</div>
                  </Flexbox.Column>
                </Flexbox.Columns>
              </Card.Header>
              <Card.Body>
                <NotFound />
                <div className="text-center">
                  <Link to="/" className="btn btn-primary">
                    <i className="fas fa-home" />
                    &nbsp;Início
                  </Link>
                </div>
              </Card.Body>
            </Card.Base>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Styles.Container>
    </AppContainer>
  )
}

export default PageNotFound

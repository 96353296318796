import List from '@/views/app/Regions'
import Create from '@/views/app/Regions/Create'
import Edit from '@/views/app/Regions/Edit'
import Polygon from '@/views/app/Regions/Polygon'
import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

function RegionsRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
      <Route path={`${path}`} exact>
        <List title="Petis - Parceiros / Regiões" />
      </Route>
      <Route path={`${path}/form/add`} exact>
        <Create title="Petis - Parceiros / Regiões / Nova" />
      </Route>
        <Route path={`${path}/:id/polygon`} exact>
          <Polygon title="Petis - Parceiros / Regiões / Demarcar" />
        </Route>
        <Route path={`${path}/:id/edit`} exact>
          <Edit title="Petis - Parceiros / Regiões / Editar" />
        </Route>
    </div>
  )
}

export default RegionsRoutes

/* eslint-disable no-shadow */
import { Flexbox, Card } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Bars from './Bars'

function RatingCard({ avgRating }) {
  const getRatingLabel = (rating) => {
    if (rating >= 0 && rating <= 1) return 'Ruim'
    if (rating > 1 && rating <= 2) return 'Regular'
    if (rating > 2 && rating <= 3) return 'Bom'
    if (rating > 3 && rating <= 4) return 'Ótimo'
    if (rating > 4 && rating <= 5) return 'Excelente'
    return ''
  }

  const ratingLabel = getRatingLabel(avgRating.rating)
  const maxCount = Math.max(
    ...avgRating.ratingDistribution.map((r) => r.count),
    1,
  )

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Avaliação média da clínica</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <Link
              to={`/app/ratings?q[rated.fullName_eq]=${encodeURIComponent(
                avgRating?.licensed?.fullName,
              )}`}
              className="btn btn-sm btn-link"
            >
              &nbsp;Ver avaliações
            </Link>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {avgRating?.rating ? (
          <Flexbox.Columns className="align-center">
            <Flexbox.Column className="text-center">
              <div className="h1 text-bold">{avgRating?.rating}</div>
              <div className="text-gray text-bold text-small">de 5</div>
              <small>{ratingLabel}</small>
            </Flexbox.Column>
            <Flexbox.Column className="col-6 align-end">
              {Array.from({ length: 5 }, (_, index) => {
                const stars = 5 - index
                const count = avgRating?.ratingDistribution?.find((r) => r.rate === stars)
                    ?.count || 0
                return (
                  <Bars
                    key={stars}
                    stars={stars}
                    count={count}
                    maxCount={maxCount}
                  />
                )
              })}
              <div className="text-gray text-bold text-small text-center mt-2">
                {avgRating?.totalRatings}{' '}
                {avgRating?.totalRatings === 1 ? 'avaliação' : 'avaliações'}
              </div>
            </Flexbox.Column>
          </Flexbox.Columns>
        ) : (
          <div>Esta clínica ainda não possui avaliação</div>
        )}
      </Card.Body>
    </Card.Base>
  )
}

RatingCard.propTypes = {
  avgRating: PropTypes.objectOf(Object).isRequired,
}

export default RatingCard

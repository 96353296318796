import { Styles } from '@/components'
import { castrationUrl } from '@/lib/urlMaker'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import allowedActions from './allowedActions'

function Actions({ castration }) {
  const actions = allowedActions(castration?.status)
  const { pathname } = useLocation()

  return (
    <Styles.ActionsContainer>
      {
        actions.includes('approve') && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/approve`}>
            <i className="fas fa-check" />
            &nbsp;Aprovar
          </Link>
        )
      }
      {
        actions.includes('confirm') && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/confirm`}>
            <i className="fas fa-check" />
            &nbsp;Confirmar castração
          </Link>
        )
      }
      {
        actions.includes('reopen') && !castration.destination && (
          <Link className="btn btn-sm btn-primary mx-1" to={`${pathname}/reopen`}>
            <i className="fas fa-undo" />
            &nbsp;Reabrir
          </Link>
        )
      }
      {
        actions.includes('approveWithClinic') && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/approve-with-clinic`}>
            <i className="fas fa-check" />
            &nbsp;Aprovar com a clínica
          </Link>
        )
      }
      {
        actions.includes('reject') && (
          <Link className="btn btn-sm btn-error mx-1" to={`${pathname}/reject`}>
            <i className="fas fa-ban" />
            &nbsp;Rejeitar
          </Link>
        )
      }
      {
        actions.includes('cancel') && (
          <Link className="btn btn-sm btn-error mx-1" to={`${pathname}/cancel`}>
            <i className="fas fa-ban" />
            &nbsp;Cancelar
          </Link>
        )
      }
      {
        actions.includes('revision') && (
          <Link className="btn btn-sm btn-link mx-1" to={`${pathname}/revision`}>
            <i className="fas fa-question" />
            &nbsp;Enviar para vistoria
          </Link>
        )
      }
      {
        actions.includes('correction') && (
          <Link className="btn btn-sm btn-link mx-1" to={`${pathname}/correction`}>
            <i className="fas fa-edit" />
            &nbsp;Solicitar correção
          </Link>
        )
      }
      {
        actions.includes('changeClinic') && (
          <Link className="btn btn-sm btn-primary mx-1" to={`${pathname}/change-clinic`}>
            <i className="fas fa-edit" />
            &nbsp;Trocar clínica
          </Link>
        )
      }
      <a className="btn btn-sm btn-link mx-1" href={castrationUrl(castration)} target="_blank" rel="noopener noreferrer">
        <i className="fas fa-print" />
        &nbsp;Imprimir
      </a>
    </Styles.ActionsContainer>
  )
}

Actions.propTypes = {
  castration: PropTypes.objectOf(Object).isRequired,
}

export default Actions

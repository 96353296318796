/* eslint-disable react/jsx-props-no-spreading */
import { Filter, Form } from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'
import React from 'react'
// import { Container } from './styles';

function FilterCastrations() {
  return (
    <Filter
      initialValues={{
        createdAtPeriod: [null, null],
        startedAtPeriod: [null, null],
        performedAtPeriod: [null, null],
        animalCategoryId: '',
        animalGender: '',
        statusCastration: '',
      }}
      title="Filtrar castrações"
      renderForm={({ values }) => (
        <>
          <div className="divider text-center" data-content="Períodos" />
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="createdAtPeriod">Solicitadas de - até</Form.Label>
            <Form.DatePickerField
              placeholderText="Data início - Data fim"
              dateFormat="dd/MM/yyyy"
              startDate={values.createdAtPeriod[0] ? new Date(values.createdAtPeriod[0]) : null}
              endDate={values.createdAtPeriod[1] ? new Date(values.createdAtPeriod[1]) : null}
              className="form-input input-sm"
              selectsRange
              isClearable
              name="createdAtPeriod"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="startedAtPeriod">Iniciadas de - até</Form.Label>
            <Form.DatePickerField
              placeholderText="Data início - Data fim"
              dateFormat="dd/MM/yyyy"
              startDate={values.startedAtPeriod[0] ? new Date(values.startedAtPeriod[0]) : null}
              endDate={values.startedAtPeriod[1] ? new Date(values.startedAtPeriod[1]) : null}
              className="form-input input-sm"
              selectsRange
              isClearable
              name="startedAtPeriod"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="performedAtPeriod">Atendimento finalizado de - até</Form.Label>
            <Form.DatePickerField
              placeholderText="Data início - Data fim"
              dateFormat="dd/MM/yyyy"
              startDate={values.performedAtPeriod[0] ? new Date(values.performedAtPeriod[0]) : null}
              endDate={values.performedAtPeriod[1] ? new Date(values.performedAtPeriod[1]) : null}
              className="form-input input-sm"
              selectsRange
              isClearable
              name="performedAtPeriod"
            />
          </Form.Group>
          <div className="divider text-center" data-content="Animal" />
          <Form.Group>
            <Form.Label htmlFor="animalCategoryId">Espécie do pet</Form.Label>
            <Form.ValidationField name="animalCategoryId">
              {
                ({ field }) => (
                  <Form.Select
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id="animalCategoryId"
                    className="select-sm"
                    placeholder="Espécie do pet"
                  >
                    <option value="">Todos</option>
                    <option value="1">Cachorro</option>
                    <option value="2">Gato</option>
                  </Form.Select>
                )
              }
            </Form.ValidationField>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="animalGender">Gênero do pet</Form.Label>
            <Form.ValidationField name="animalGender">
              {
                ({ field }) => (
                  <Form.Select
                    {...field}
                    id="animalGender"
                    className="select-sm"
                    placeholder="Gênero do pet"
                  >
                    <option value="">Todos</option>
                    <option value="male">Macho</option>
                    <option value="female">Fêmea</option>
                  </Form.Select>
                )
              }
            </Form.ValidationField>
          </Form.Group>
          <div className="divider text-center" data-content="Castração" />
          <Form.Group>
            <Form.Label htmlFor="statusCastration">Status</Form.Label>
            <Form.ValidationField name="statusCastration">
              {({ field }) => (
                <Form.Select
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  id="statusCastration"
                  className="select-sm"
                  placeholder="Status da castração"
                >
                  <option value="">Todos</option>
                  {Object.keys(singularStatusMapLabel).map((status) => (
                    <option key={status} value={status}>
                      {singularStatusMapLabel[status]}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Form.ValidationField>
          </Form.Group>
        </>
      )}
    />
  )
}

export default FilterCastrations

import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import { useEffect, useState } from 'react'

/**
 *
 * @param {*} initialValue
 * @returns {google.maps.drawing.DrawingManager} drawingManager
 */
export function useDrawingManager(initialValue = null) {
  const map = useMap()
  const drawing = useMapsLibrary('drawing')

  const [drawingManager, setDrawingManager] = useState(initialValue)

  useEffect(() => {
    if (!map || !drawing) return

    // https://developers.google.com/maps/documentation/javascript/reference/drawing
    const newDrawingManager = new drawing.DrawingManager({
      map,
      drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      polygonOptions: {
        editable: true,
        draggable: true,
        fillColor: '#e49042',
        fillOpacity: 0.5,
        strokeColor: '#e49042',
      },
    })

    setDrawingManager(newDrawingManager)

    // eslint-disable-next-line consistent-return
    return () => {
      newDrawingManager.setMap(null)
    }
  }, [drawing, map])

  return drawingManager
}

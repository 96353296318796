import React from 'react'
import List from '@/views/app/Ratings'
import { Route, useRouteMatch } from 'react-router-dom'

function RatingsRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
      <Route path={`${path}`} exact>
        <List title="Petis - Parceiros / Avaliações das Clínicas" />
      </Route>
    </div>
  )
}

export default RatingsRoutes

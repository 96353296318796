import { ListManager, PageHeader, Styles } from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import reports from '@/services/api/reports'
import React from 'react'

function CastrationsByCras({ title }) {
  usePageTitle({ title })

  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.castrationsByCras, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações solicitadas por usuários com CRAS"
        subtitle="Relatório de contagem de usuários com castração concluída e que estão no CRAS"
        icon="fas fa-user-circle"
      />
      <ListManager
        count={data?.rows?.length || 0}
        loading={loading}
        error={error}
      >
        <table className="table table-hover text-small">
          <thead>
            <tr>
              <th>Ano</th>
              <th className="text-right" width="200px">
                Quantidade total
              </th>
            </tr>
          </thead>
          <tbody>
            {Boolean(data?.rows)
              && data?.rows.map((row) => (
                <tr key={row?.year}>
                  <td>{row?.year}</td>
                  <td className="text-right">{row?.total}</td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td />
              <td width="200px" className="text-bold text-right">
                Total: {data?.totalSum}
              </td>
            </tr>
          </tfoot>
        </table>
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsByCras

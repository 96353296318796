import { Avatar } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'

function ClinicsBalanceCard({ clinicsBalance }) {
  return (
    <div className="panel panel-custom">
      <div className="panel-header">
        <div className="panel-title h5">Balanço das clínicas</div>
        <div>
          <small>
            Aqui você encontra o saldo disponível de castrações para cada
            clínica e o total de procedimentos na caixa de entrada.
          </small>
        </div>
        <div className="divider" />
        <div
          className="panel-body"
          style={{ maxHeight: '360px', overflow: 'auto' }}
        >
          {clinicsBalance?.length > 0 ? (
            <table className="table table-striped table-hover text-small">
              <thead>
                <tr>
                  <th width={300}>Clínica</th>
                  <th width={300}>Saldo</th>
                  <th width={100}>Caixa de entrada</th>
                </tr>
              </thead>
              <tbody>
                {clinicsBalance.map((clinic) => (
                  <tr key={clinic?.clinicId}>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        name={clinic?.clinicName}
                        photoURL={clinic?.clinicPhotoURL}
                        className="mr-1"
                        alt="Imagem da clínica"
                      />
                      {clinic?.clinicName}
                    </td>
                    <td
                      className={`${
                        clinic?.balanceAmount === 0 ? 'text-error' : ''
                      }`}
                    >
                      {clinic?.balanceAmount}
                    </td>
                    <td className="text-center">{clinic?.inbox}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="empty">
              <div className="empty-icon">
                <i className="far fa-3x fa-frown-open" />
              </div>
              <p className="empty-title h5">Ops</p>
              <p className="empty-subtitle">
                Ainda não há registros para exibir
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ClinicsBalanceCard.propTypes = {
  clinicsBalance: PropTypes.arrayOf(Object).isRequired,
}

export default ClinicsBalanceCard

import {
  Avatar, Filter, Form, Mask,
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { LICENSING } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import phoneNumber from '@/lib/masks/phoneNumber'
import protectors from '@/services/api/protectors'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Licensed({ title }) {
  usePageTitle({ title })
  const { pathname } = useLocation()
  const query = useQuery()
  const params = { ...query, q: { ...query?.q, status: 'approved' } }
  const { data, error, loading } = useAxiosRequest(protectors.list, params)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Protetores(as) credenciados(as)"
        icon="fas fa-hand-holding-heart"
        subtitle="Estes são os protetores e protetoras independentes licenciados"
        rightAction={() => (
          <Filter
            initialValues={{ fullName: '', documentNumber: '', phoneNumber: '' }}
            title="Filtrar protetores(as)"
            renderForm={({ setFieldValue }) => (
              <>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="fullName">Nome do(a) protetor(a)</Form.Label>
                  <Form.ValidationField
                    name="fullName"
                    className="input-sm"
                    autoFocus
                    id="fullName"
                    autoComplete="none"
                    placeholder="Fulano da Silva"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="documentNumber">Documento do(a) protetor(a)</Form.Label>
                  <Form.ValidationField name="documentNumber">
                    {
                      ({ field }) => (
                        <Mask.CpfCnpj
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="CPF/CNPJ do protetor(a)"
                          id="documentNumber"
                          onAccept={(value) => {
                            setFieldValue('documentNumber', value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="phoneNumber">Telefone do(a) protetor(a)</Form.Label>
                  <Form.ValidationField name="phoneNumber">
                    {
                      ({ field }) => (
                        <Mask.Phone
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="Telefone do protetor(a)"
                          id="phoneNumber"
                          onAccept={(value) => {
                            setFieldValue('phoneNumber', value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
              </>
            )}
          />
        )}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Protetor(a)</th>
                    <th>Telefone</th>
                    <th>Status</th>
                    <th>Solicitado em</th>
                    <th width="1"> </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((licensing) => (
                      <tr key={licensing?.id}>
                        <td>
                          <Avatar
                            avatarSize="sm"
                            className="mr-1"
                            name={licensing?.licensed?.fullName}
                            photoURL={licensing?.licensed?.photoURL}
                            alt="Imagem de usuário"
                          />
                          {licensing?.licensed?.fullName}
                        </td>
                        <td>{phoneNumber(licensing?.licensed?.phoneNumber || '-')}</td>
                        <td>{LICENSING.status.t(licensing?.status)}</td>
                        <td>{dateMask(licensing?.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
                        <td>
                          <Link
                            to={`${pathname}/${licensing?.id}`}
                            className="btn btn-action btn-sm btn-primary tooltip tooltip-left"
                            data-tooltip="Exibir"
                          >
                            <i className="fas fa-eye" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default Licensed

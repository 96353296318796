import { LoadManager, PageHeader, Styles } from '@/components'
import envs from '@/constants/envs'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import geometryPolygonToGeoJSON from '@/lib/geo/geometryToGeoJSON'
import people from '@/services/api/people'
import regions from '@/services/api/regions'
import {
  APIProvider,
} from '@vis.gl/react-google-maps'
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import MapComponent from './MapComponent'
import { MapContainer } from './styles'

function Polygon({ title }) {
  const newPolygonRef = useRef(null)
  const history = useHistory()
  const [updating, setUpdating] = useState(false)
  usePageTitle({ title })
  const { id } = useParams()
  const { data, loading, error } = useAxiosRequest(regions.show, id)
  const [partner, setPartner] = useState(null)

  async function fetchPartner() {
    const { data: partnerData } = await people.partner()
    setPartner(partnerData)
  }

  const onConfirm = useCallback(async () => {
    try {
      if (!newPolygonRef.current) throw new Error('Demarque a região')
      setUpdating(true)
      await regions.updatePolygon(id, newPolygonRef.current)
      toast.success('Região demarcada com sucesso')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setUpdating(false)
    }
  }, [history, id])

  const center = partner?.location ? {
    lat: partner?.location?.coordinates[1],
    lng: partner?.location?.coordinates[0],
  } : null

  useEffect(() => {
    fetchPartner()
  }, [])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Demarcação da região"
        subtitle="Demarque a área desta região no mapa"
        icon="fas fa-route"
      />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <div>
                Região: <b>{data?.name}</b>
              </div>
              <div className="my-2">
                Demarque a região no mapa
              </div>
              <MapContainer>
                <APIProvider apiKey={envs.REACT_APP_GOOGLE_MAPS_API_KEY}>
                  {
                    Boolean(center) && (
                      <MapComponent
                        polygon={data?.polygon}
                        onChangePolygon={
                          (snapshot) => {
                            newPolygonRef.current = geometryPolygonToGeoJSON(snapshot)
                          }
                        }
                        center={center}
                      />
                    )
                  }
                </APIProvider>
              </MapContainer>
              <div className="text-right mt-2">
                <button type="button" onClick={onConfirm} className={`btn btn-primary ${updating ? 'loading' : ''}`}>
                  <i className="fas fa-save" />
                  &nbsp;Salvar demarcação
                </button>
              </div>
            </>
          )
        }

      </LoadManager>
    </Styles.Container>
  )
}

export default Polygon

import {
  Card,
  Flexbox, LoadManager, PageHeader, Styles, UserCard,
} from '@/components'
import { LICENSING_STATUS_MAP } from '@/constants/licensings'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useClinic from '@/hooks/useClinic'
import usePageTitle from '@/hooks/usePageTitle'
import { setClinic } from '@/reducers/clinic'
import clinics from '@/services/api/clinics'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Actions from './components/Actions'
import BalanceCard from './components/BalanceCard'
import TransactionsCard from './components/TransactionsCard'
import RatingCard from './components/RatingClinicCard'

function Show({ title }) {
  const { state: { clinic }, dispatch } = useClinic()
  usePageTitle({ title })
  const { id } = useParams()
  const { data, error, loading } = useAxiosRequest(clinics.show, id)

  useEffect(() => {
    dispatch(setClinic(data))
  }, [data, dispatch])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader icon="fas fa-clinic-medical" title={`Clínica #${id}`} />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(clinic) && (
            <>
              <Actions licensing={clinic} />
              <div>
                Situação: <b>{LICENSING_STATUS_MAP[clinic?.status]}</b>
              </div>
              {
                clinic?.status === 'requested' && (
                  <div className="toast toast-primary">
                    <div className="text-bold">Atenção</div>
                    <p>
                      Para adicionar saldos de castração a esta clínica,
                      antes você precisa aprovar o seu credenciamento
                    </p>
                  </div>
                )
              }
              <Flexbox.Columns className="mt-2">
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <UserCard
                    title="Clínica"
                    user={clinic?.licensed}
                    renderFooter={() => (
                      <Card.Footer>
                        <div className="divider" />
                        {clinic?.address?.formattedAddress}
                      </Card.Footer>
                    )}
                  />
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <BalanceCard licensing={clinic} />
                </Flexbox.Column>
                {
                  clinic?.status === 'approved' && (
                    <>
                      <Flexbox.Column className="col-12 col-sm-12 my-2">
                        <RatingCard avgRating={clinic} />
                      </Flexbox.Column>
                      <Flexbox.Column className="col-12 col-sm-12 my-2">
                        <TransactionsCard transactions={clinic?.lastTransactions} />
                      </Flexbox.Column>
                    </>
                  )
                }
              </Flexbox.Columns>
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

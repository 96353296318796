import React from 'react'
import PropTypes from 'prop-types'
import { Flexbox } from '@/components'

function Stars({ rate }) {
  return (
    <Flexbox.Columns className="col-gapless">
      {Array(5)
        .fill(null)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Flexbox.Column key={index} className="col-auto mr-1">
            <i
              className={`fas ${
                index < rate ? 'fa-star text-primary' : 'fa-star text-gray'
              }`}
            />
          </Flexbox.Column>
        ))}
    </Flexbox.Columns>
  )
}

Stars.propTypes = {
  rate: PropTypes.number.isRequired,
}

export default Stars

import styled from 'styled-components'
import notFound from '@/assets/img/404.png'

export const Container = styled.div.attrs({
  className: 'container',
})``

export const PageNotFound = styled.img.attrs({
  src: notFound,
})`
  width: 100%;
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

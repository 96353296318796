/* eslint-disable react/prop-types */
import {
  ControlPosition, Map, MapControl,
  useMapsLibrary,
} from '@vis.gl/react-google-maps'
import React, { useState } from 'react'
import PlaceAutocomplete from './PlaceAutocomplete'
import { UndoRedoControl } from './UndoRedoControl'
import { useDrawingManager } from './useDrawingManager'

function MapComponent({ center, onChangePolygon, polygon }) {
  const [loaded, setLoaded] = useState(false)
  const drawingManager = useDrawingManager()
  useMapsLibrary('places')

  return (
    <>
      <Map
        style={{ width: '100%', height: '100%' }}
        defaultCenter={center}
        defaultZoom={12}
        minZoom={12}
        gestureHandling="greedy"
        disableDefaultUI
        onTilesLoaded={() => setLoaded(true)}
      />
      {
        loaded && (
          <>
            <MapControl position={ControlPosition.TOP_LEFT}>
              <div className="m-2">
                <PlaceAutocomplete />
              </div>
            </MapControl>
            <MapControl position={ControlPosition.RIGHT_BOTTOM}>
              <UndoRedoControl
                onChangePolygon={onChangePolygon}
                polygon={polygon}
                drawingManager={drawingManager}
              />
            </MapControl>
          </>
        )
      }
    </>
  )
}

export default MapComponent

import { ListManager, PageHeader, Styles } from '@/components'
import React from 'react'
import usePageTitle from '@/hooks/usePageTitle'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import reports from '@/services/api/reports'
import useQuery from '@/hooks/useQuery'
import { REGION } from '@/lib/enums'
import FilterCastrations from '../components/FilterCastrations'

function CastrationsByRegion({ title }) {
  usePageTitle({ title })

  const query = useQuery()

  const { data, loading, error } = useAxiosRequest(reports.castrationsByRegion, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações por região"
        subtitle="Mostra a quantidade de castrações solicitadas por região"
        icon="fas fa-map"
        rightAction={() => <FilterCastrations />}
      />
      <ListManager count={data?.length || 0} loading={loading} error={error}>
        {
          data?.length > 0 && (
            <small>
              <table className="table">
                <thead>
                  <tr>
                    <th>Região</th>
                    <th>Tipo</th>
                    <th className="text-right">Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((item) => (
                      <tr key={item?.regionId}>
                        <td>
                          {item?.regionName}
                          {Boolean(item?.regionDescription) && (
                            <div><small>{item.regionDescription}</small></div>
                          )}
                        </td>
                        <td>{REGION.type.t(item?.regionType)}</td>
                        <td className="text-right">{item?.count}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </small>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsByRegion

import {
  Flexbox, LoadManager, PageHeader, Styles,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import home from '@/services/api/home'
import React, {
  useCallback, useLayoutEffect, useState,
} from 'react'
import CastrationsCard from './components/CastrationsCard'
import BatchesCard from './components/BatchesCard'
import LicensingsCard from './components/LicensingsCard'
import CastrationsChart from './components/CastrationsChart'
import ClinicsBalanceCard from './components/ClinicsBalanceCard'

function Dashboard({ title }) {
  usePageTitle({ title })
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchDashboard = useCallback(async () => {
    try {
      setLoading(true)
      const res = await home.dashboard()
      setData(res.data)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }, [setData, setError, setLoading])

  useLayoutEffect(() => {
    fetchDashboard()
  }, [fetchDashboard])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        icon="fas fa-tachometer-alt"
        title="Dashboard"
        subtitle="Resumo geral do Petis para você"
      />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <Flexbox.Columns>
                <Flexbox.Column className="col-12 my-2">
                  <CastrationsCard castrations={data?.castrations} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <ClinicsBalanceCard clinicsBalance={data?.clinicsBalance} />
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <BatchesCard batches={data?.batches} />
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <LicensingsCard licensings={data?.licensings} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <CastrationsChart counts={data?.counts?.castrations} />
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Dashboard

import React from 'react'
import { Card, Flexbox, Styles } from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import { Link, useHistory } from 'react-router-dom'
import useAuth from '@/hooks/useAuth'
import { BrandLogo } from './styles'

function Alert({ title }) {
  usePageTitle({ title })
  const auth = useAuth()
  const history = useHistory()

  function handleSignOut() {
    auth.signOut(() => {
      history.replace('/')
    })
  }

  return (
    <Styles.Container className="grid-xl">
      <Flexbox.Columns>
        <Flexbox.Column className="col-12 col-md-12 my-2">
          <Card.Base>
            <Card.Header>
              <Flexbox.Columns className="align-center">
                <Flexbox.Column className="col-auto">
                  <BrandLogo />
                </Flexbox.Column>
                <Flexbox.Column>
                  <div className="h4">Petis</div>
                </Flexbox.Column>
              </Flexbox.Columns>
            </Card.Header>
            <Card.Body>
              <div>
                <div className="text-bold pb-2 mb-2">
                  <i className="fas fa-exclamation-triangle text-warning" /> Não
                  encontramos nenhuma licença associada ao seu usuário. Por
                  favor, procure o administrador do sistema para liberar o
                  acesso.
                </div>
                Olá, {auth.user?.fullName}! Esta é a Plataforma do parceiro
                Petis.
                <div className="text-small pt-2 pb-2">
                  Para cadastrar seus pets, solicitar castrações, feiras de
                  adoção, carteira de vacinas e muito mais, baixe o aplicativo{' '}
                  Petis na loja de aplicativos do seu celular.
                </div>
              </div>
              <div>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.paliari.petis"
                  className="btn mr-2"
                  target="_blank"
                >
                  <i className="fab fa-android" />
                  &nbsp;Petis para Android
                </Link>
                <Link
                  to="https://apps.apple.com/br/app/petis/id1413161923"
                  className="btn"
                  target="_blank"
                >
                  <i className="fab fa-apple" />
                  &nbsp;Petis para iOS
                </Link>
              </div>

              <div className="my-2">
                Acesse o site do Petis em{' '}
                <a target="_blank" rel="noreferrer" href="https://petis.me">
                  https://petis.me
                </a>
              </div>
              <div className="divider" />
              <div>
                <i
                  className="fab fa-instagram"
                  style={{
                    background:
                      'linear-gradient(45deg, #f58529, #f77737, #d93f30, #bc2a8d, #8a3ab9)',
                    backgroundClip: 'text',
                    color: 'transparent',
                  }}
                />{' '}
                Siga o Petis no{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://instagram.com/petis.me"
                >
                  Instagram
                </a>
              </div>
              <div>
                <i className="fab fa-facebook" style={{ color: '#1877f2' }} />{' '}
                Curta o Petis no{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://facebook.com/app.petis.me"
                >
                  Facebook
                </a>
              </div>
              <div className="divider" />
              <div>Desenvolvido por:</div>
              <div>
                <b>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://paliari.com.br"
                  >
                    Paliari Engenharia de Software
                  </a>
                </b>
              </div>
              <div className="divider" />
            </Card.Body>
            <Card.Footer className="text-right">
              {auth.user ? (
                <div className="h6 text-right p-2">
                  <a
                    onClick={handleSignOut}
                    aria-hidden="true"
                    className="text-error c-hand"
                  >
                    <i className="fas fa-sign-out-alt fa-fw" />
                    &nbsp;Sair
                  </a>
                </div>
              ) : null}
            </Card.Footer>
          </Card.Base>
        </Flexbox.Column>
      </Flexbox.Columns>
    </Styles.Container>
  )
}

export default Alert

export default {
  animal: {
    size: {
      enum: {
        TOY: 'toy',
        SMALL: 'small',
        MEDIUM: 'medium',
        BIG: 'big',
        GIANT: 'giant',
      },
      translations: {
        toy: 'Toy',
        small: 'Pequeno',
        medium: 'Médio',
        big: 'Grande',
        giant: 'Gigante',
      },
    },
    gender: {
      enum: {
        MALE: 'male',
        FEMALE: 'female',
      },
      translations: {
        male: 'Macho',
        female: 'Fêmea',
      },
    },
  },
  adoption: {
    status: {
      enum: {
        REQUESTED: 'requested',
        CONFIRMED: 'confirmed',
        REJECTED: 'rejected',
        CANCELED: 'canceled',
      },
      translations: {
        requested: 'Solicitada',
        confirmed: 'Confirmada',
        rejected: 'Rejeitada',
        canceled: 'Cancelada',
      },
    },
  },
  licensing: {
    type: {
      enum: {
        CASTRATOR: 'castrator',
        ROTECTOR: 'protector',
        CHIPS_APPLYER: 'chipsApplyer',
        NGO: 'ngo',
      },
      translations: {
        castrator: 'Castrador',
        protector: 'Protetor',
        chipsApplyer: 'Aplicador de Chip',
        ngo: 'ONG',
      },
    },
    status: {
      enum: {
        REQUESTED: 'requested',
        APPROVED: 'approved',
        REJECTED: 'rejected',
        REVOKED: 'revoked',
      },
      translations: {
        requested: 'Solicitado',
        approved: 'Aprovado',
        rejected: 'Rejeitado',
        revoked: 'Revogado',
      },
    },
  },
  castration: {
    status: {
      enum: {
        REQUESTED: 'requested',
        REJECTED: 'rejected',
        CANCELED: 'canceled',
        PRE_APPROVED: 'preApproved',
        APPROVED: 'approved',
        REVISION: 'revision',
        SCHEDULED: 'scheduled',
        ATTENDANCE: 'attendance',
        IN_TREATMENT: 'inTreatment',
        CORRECTED: 'corrected',
        CORRECTION: 'correction',
        AWAITING_CONFIRMATION: 'awaitingConfirmation',
        COMPLETED: 'completed',
      },
      translations: {
        requested: 'Solicitada',
        rejected: 'Rejeitada',
        canceled: 'Cancelada',
        inTreatment: 'Em tratamento',
        preApproved: 'Pré aprovada',
        approved: 'Aprovada',
        revision: 'Em vistoria',
        scheduled: 'Agendada',
        correction: 'Correção',
        corrected: 'Corrigida pelo tutor',
        attendance: 'Em atendimento',
        awaitingConfirmation: 'Aguardando confirmar',
        completed: 'Concluída',
      },
    },
  },
  mistreatment: {
    status: {
      enum: {
        OPENED: 'opened',
        CHECKING: 'checking',
        DONE: 'done',
        REJECTED: 'rejected',
        CANCELED: 'canceled',
      },
      translations: {
        opened: 'Aberto',
        checking: 'Em verificação',
        done: 'Pronto',
        rejected: 'Rejeitado',
        canceled: 'Cancelado',
      },
    },
    result: {
      enum: {
        AWAITING: 'awaiting',
        MEASURES_APPLIED: 'measuresApplied',
        UNFOUNDED: 'unfounded',
        CANCELED: 'canceled',
      },
      translations: {
        awaiting: 'Aguardando',
        measuresApplied: 'Medidas legais aplicadas',
        unfounded: 'Não aplicável',
        canceled: 'Cancelado',
      },
    },
  },
  complaint: {
    type: {
      enum: {
        INAPPROPRIATE_CONTENT: 'inappropriateContent',
        VIOLENCE: 'violence',
        IS_NOT_A_PET: 'isNotAPet',
        FRAUD: 'fraud',
        OTHER: 'other',
      },
      translations: {
        inappropriateContent: 'Conteúdo inapropriado',
        violence: 'Violência',
        isNotAPet: 'Não é um animal',
        fraud: 'Fraude',
        other: 'Outra',
      },
    },
  },
  castrationCorrectionRequired: {
    type: {
      enum: {
        ADDRESS_REQUIRED: 'addressRequired',
        PHOTO_REQUIRED: 'photoRequired',
        SELFIE_REQUIRED: 'selfieRequired',
      },
      translations: {
        addressRequired: 'Endereço',
        photoRequired: 'Foto',
        selfieRequired: 'Selfie com documento',
      },
    },
  },
  region: {
    type: {
      enum: {
        ZONE: 'zone',
        NEIGHBORHOOD: 'neighborhood',
        STREET: 'street',
        CUSTOM: 'custom',
      },
      translations: {
        zone: 'Zona',
        neighborhood: 'Bairro',
        street: 'Rua',
        custom: 'Região customizada',
      },
    },
  },
}

import { Flexbox, Form } from '@/components'
import yup from '@/lib/yupPt'
import animals from '@/services/api/animals'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  diedAt: yup.date().max(new Date()).required(),
})
export default function DiedForm({ onDied }) {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const onClose = useCallback(() => {
    setActive(false)
  }, [])

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const { data } = await animals.died(id, values)
      onDied(data)
      toast.success('Óbito informado com sucesso')
      setLoading(false)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <div>
      <a onClick={() => setActive(true)} className="btn btn-link btn-sm">
        <i className="fas fa-cross" />
        &nbsp;Informar óbito
      </a>
      {
        active && (
          <fieldset disabled={loading}>
            <Form.Formik
              initialValues={{
                description: '',
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
              validateOnMount
            >
              {
                ({
                  touched, errors, isValid,
                }) => (
                  <Form.ValidationForm>
                    <div className={`modal ${active ? 'active' : ''}`}>
                      <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
                      <div className="modal-container">
                        <div className="modal-header">
                          <a
                            type="button"
                            className="btn btn-clear float-right"
                            onClick={onClose}
                          />
                          <div className="modal-title h5">
                            Nova castração
                          </div>
                        </div>
                        <div className="modal-body" style={{ minHeight: 400 }}>
                          <Flexbox.Columns className="p-2 align-center">
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.diedAt && errors?.diedAt ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="diedAt">Data do óbito <span className="text-error">*</span></Form.Label>
                                <Form.ValidationField
                                  name="diedAt"
                                  id="diedAt"
                                >
                                  {
                                    ({ field }) => (
                                      <Form.DatePickerField
                                        name="diedAt"
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={new Date()}
                                        className="form-input"
                                        id="diedAt"
                                        placeholderText="Óbito em"
                                        selected={field.value || null}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.diedAt && errors?.diedAt
                                    ? <Form.Hint className="text-error">{errors.diedAt}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" disabled={!isValid || loading} className={`btn btn-primary ${loading ? 'loading' : ''}`}>
                            <i className="fas fa-check" />
                            &nbsp;Informar óbito
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form.ValidationForm>
                )
              }
            </Form.Formik>
          </fieldset>
        )
      }
    </div>
  )
}

DiedForm.propTypes = {
  onDied: PropTypes.func.isRequired,
}
